<template>
  <div>
    <div>
      <dx-data-grid
        id="monthlySalesGrid"
        ref="monthlyGridRef"
        :data-source="AmazonMonthlySalesDataSource"
        :height="setHeight"
        :cache-enabled="false"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        @initialized="onInitialized"
        @content-ready="onContentReady"
        @toolbar-preparing="onToolbarPreparing($event)"
        @cell-prepared="onCellPrepared($event)"
        @option-changed="onOptionChanged"
        @editor-preparing="onEditorPreparing"
      >
        <!--region    ::DataGrid base components -->
        <dx-sorting mode="single" />
        <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
        <dx-search-panel :visible="true" :width="300" placeholder="Search..." />
        <template #filterToolbar>
          <div class="d-flex">
            <div class="mr-1">
              <dx-util-text-box
                v-model.trim="accountNo"
                placeholder="Account No"
                :visible="hasPermission"
                :on-enter-key="onFilterByAccountNo"
                :show-clear-button="true"
                mode="text"
              />
            </div>
            <div class="mr-1">
              <dx-util-select-box v-model="selectedStore" :data-source="stores" display-expr="text" value-expr="value" @value-changed="searchByFilter" />
            </div>
            <!-- <div>
              <dx-util-button id="uploadButton" text="Upload" location="after" />
            </div> -->
          </div>
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
        <!--endregion ::DataGrid base components -->
        <!--region    ::DataGrid columns -->
        <dx-column data-field="storeName" caption="Store" />
        <dx-column data-field="year" caption="Year" />
        <dx-column data-field="month" caption="Month" />
        <dx-column data-field="sales" caption="Gross Sales" :format="'$ #,##0.##'" />
        <dx-column data-field="refunds" caption="Refunds" :format="'$ #,##0.##'" />
        <dx-column data-field="refundsPercent" caption="Refunds %" type="percent" :precision="2" />
        <dx-column data-field="netSales" caption="Net Sales" :format="'$ #,##0.##'" />
        <dx-column data-field="quantity" caption="Item Qty Sold" />
        <dx-column data-field="cogs" caption="COGS" :format="'$ #,##0.##'" />
        <dx-column data-field="cogsPercent" caption="COGS %" type="percent" :precision="2" />
        <dx-column data-field="amzFulfillment" caption="Amazon Fees" :format="'$ #,##0.##'" />
        <dx-column data-field="amzFulfillmentPercent" caption="Amazon Fees %" type="percent" :precision="2" />
        <dx-column data-field="warehouseFees" caption="Warehouse Fees" :format="'$ #,##0.##'" header-cell-template="header-warehouse-fees" />
        <dx-column data-field="warehouseFeesPercent" caption="Warehouse Fees %" type="percent" :precision="2" header-cell-template="header-warehouse-fees" />
        <template #header-warehouse-fees="{ data }">
            <div v-b-tooltip.d100.hover.bottom.v-primary title="Warehouse Fees include service fees charged by your Prep Center">
              {{ data.column.caption }}
            </div>
          </template>
        <dx-column data-field="unmatchedQty" caption="Unmatched Item Qty" :format="'#,##0'" />
        <dx-column data-field="unmatchedSales" caption="Unmatched COGS" :format="'$ #,##0.##'" />
        <dx-column data-field="profit" caption="Profit" :format="'$ #,##0.##'" />
        <dx-column data-field="profitPercent" caption="Profit %" type="percent" :precision="2" />
        <dx-column data-field="withdrawals" caption="Withdrawals" :format="'$ #,##0.##'" />
        <!--endregion ::DataGrid columns -->
        <!--region    ::DataGrid actions  title, asin, quantiy, sku, -->
        <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
        <template #itemActions="{data}">
          <div>
            <dx-util-button type="default" icon="bi bi-info-square-fill" hint="More Information" class="ml-half" @click="openMoreInfo(data)" />
            <dx-util-button type="warning" icon="bi bi-cash-coin" hint="Update Item Costs" class="ml-half" @click="openCostUpdater(data)" />
            <dx-util-button type="danger" icon="bi bi-trash" hint="Delete Data" class="ml-half" @click="remove(data)" />
          </div>
        </template>
        <!--endregion ::DataGrid actions -->
      </dx-data-grid>
      <dx-util-popup :show-close-button="true" :drag-enabled="true"
        :close-on-outside-click="false" :show-title="true"
        :full-screen="false"
        width="1200px"
        height="100vh"
        :visible="infoVisible"
        title="How to generate monthly transaction report on Amazon?"
        @hidden="infoVisible=false"
      >
        <dx-util-scroll-view
          width="100%"
          height="100%"
        >
        <div class="text-warning">
          This module calculates Amazon Monthly Profits by accounting both Amazon and PrepShipHub cost information.
          Please follow the instructions below to generate and download a monthly report from Amazon Seller Central. Once you receive the report,
          upload the Amazon generated report in this module using the upload button. Please make sure that you select the appropriate month.
        </div>
        <div class="text-info">
          <ol>
            <li>
              <div class="py-1">
                <a target="_blank" href="https://sellercentral.amazon.com">
                  Go to Amazon Seller Central
                  <p-icon name="bi-box-arrow-up-right" color="info" />
                </a>
              </div>
            </li>
            <li>
              <div class="py-1">
                <a target="_blank" href="https://sellercentral.amazon.com/payments/dashboard/index.html">
                  Go to Menu > Reports > Payments
                  <p-icon name="bi-box-arrow-up-right" color="info" />
                </a>
              </div>
              <img src="@/assets/images/help/sales/1.png" alt="" width="500">
            </li>
            <li>
              <div class="py-1">
                <a target="_blank" href="https://sellercentral.amazon.com/payments/reports-repository">
                  Select "Reports Repository" tab
                  <p-icon name="bi-box-arrow-up-right" color="info" />
                </a>
              </div>
              <img src="@/assets/images/help/sales/2.png" alt="" width="800">
            </li>
            <li>
              <div class="py-1">
                Select the appropriate options for the report. (All, Transaction, Month) and click "Request Report" button
              </div>
              <img src="@/assets/images/help/sales/3.png" alt="" width="1000">
            </li>
            <li>
              <div class="py-1">
                Click "Download CSV" button to download the report
              </div>
              <img src="@/assets/images/help/sales/4.png" alt="" width="1000">
            </li>
          </ol>
        </div>
        </dx-util-scroll-view>
      </dx-util-popup>
      <mohthly-sales-uploader :component-id="monthlySalesUploaderComponentId" :sales-data="salesData" @on-sales-added="onUpdaterClosed" />
      <item-cost-updater :component-id="itemCostUpdaterComponentId" :month-id="monthId" @on-cost-updater-closed="onUpdaterClosed" />
      <table-popup :table-data="selectedMonthData" :component-id="tablePopupComponentId" />
      <monthly-sales-chart :component-id="chartsPopupComponentId" :chart-raw-data="chartData" />
    </div>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import SalesSearchFilter from '@/http/models/search-filters/SalesSearchFilter.js'
import useCurrentUser from '@/libs/app/current-user'
import { confirm } from 'devextreme/ui/dialog'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'
import { AmazonMonthlySalesDataSource } from './amazonMonthlySalesSnapshot'

export default {
  components: {
    'mohthly-sales-uploader': () => import('./MonthlySalesUploader.vue'),
    'item-cost-updater': () => import('./ItemCostUpdater.vue'),
    'table-popup': () => import('./TablePopup.vue'),
    'monthly-sales-chart': () => import('./MonthlySalesChart.vue'),
  },
  mixins: [GridBase, Filters],
  setup() {
    const { userCompanyId } = useCurrentUser()
    return {
      userCompanyId,
    }
  },
  data() {
    return {
      AmazonMonthlySalesDataSource,
      periods: [],
      salesData: [],
      fileInputId: 'fileInputId',
      monthlySalesUploaderComponentId: '',
      itemCostUpdaterComponentId: '',
      tablePopupComponentId: '',
      chartsPopupComponentId: '',
      detailsPopupComponentId: '',
      filters: {
        query: '',
      },
      accountNo: '',
      costItems: [],
      monthId: 0,
      infoVisible: false,
      selectedMonthData: {},
      chartData: [],
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      return this.$refs.monthlyGridRef.instance
    },
  },
  created() {
    this.getStores()
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.column.dataField === 'profit' || e.column.dataField === 'profitPercent' || e.column.dataField === 'sales') {
          e.cellElement.style.cssText = 'color: #55ab68; text-align: right; font-weight: bold;'
        } else if (e.column.dataField === 'unmatchedQty' || e.column.dataField === 'unmatchedSales') {
          e.cellElement.style.cssText = 'color: #deb144; text-align: right; font-weight: bold;'
        } else if (e.column.dataField === 'cogs' || e.column.dataField === 'cogsPercent') {
          e.cellElement.style.cssText = 'color: #f76552; text-align: right; font-weight: bold;'
        } else if (e.column.dataField === 'amzFulfillment' || e.column.dataField === 'amzFulfillmentPercent') {
          e.cellElement.style.cssText = 'color: #f76552; text-align: right; font-weight: bold;'
        } else if (e.column.dataField === 'warehouseFees' || e.column.dataField === 'warehouseFeesPercent') {
          e.cellElement.style.cssText = 'color: #f76552; text-align: right; font-weight: bold;'
        } else if (e.column.dataField === 'refunds' || e.column.dataField === 'refundsPercent') {
          e.cellElement.style.cssText = 'color: #f76552; text-align: right; font-weight: bold;'
        } else if (e.column.dataField === 'withdrawals' || e.column.dataField === 'refundsPercent') {
          e.cellElement.style.cssText = 'color: #417eb4; text-align: right; font-weight: bold;'
        }
      }
    },
    openCostUpdater(row) {
      this.monthId = row.data.id
      this.itemCostUpdaterComponentId = uuidv4()
    },
    openMoreInfo(row) {
      this.tablePopupComponentId = uuidv4()
      this.selectedMonthData = row.data
    },
    initialLoad() {
      SalesSearchFilter.setDefaultFilters()
      SalesSearchFilter.accountNo = parseInt(this.accountNo, 10)
      SalesSearchFilter.storeId = this.selectedStore
      SalesSearchFilter.companyId = this.userCompanyId
      const filters = SalesSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    searchByFilter() {
      SalesSearchFilter.setDefaultFilters()
      SalesSearchFilter.accountNo = parseInt(this.accountNo, 10)
      SalesSearchFilter.storeId = this.selectedStore
      SalesSearchFilter.q = this.filters.query
      const filters = SalesSearchFilter.getFilters()
      AmazonMonthlySalesDataSource.searchValue(filters)
      AmazonMonthlySalesDataSource.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        id: 'uploadButton',
        options: {
          id: 'uploadButton',
          hint: 'Help',
          icon: 'icon bi-question',
          type: 'default',
          onClick: () => {
            this.infoVisible = true
            // this.addPeriod()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        id: 'uploadButton',
        options: {
          text: 'Upload',
          icon: 'icon icon-psh-trade',
          type: 'success',
          onClick: () => {
            this.monthlySalesUploaderComponentId = uuidv4()
            // this.addPeriod()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        id: 'uploadButton',
        options: {
          text: 'Chart',
          icon: 'icon icon-psh-reports',
          type: 'default',
          onClick: () => {
            if (this.selectedStore === 0 || this.selectedStore === null || this.selectedStore === undefined) {
              Notify.warning('Please select a store to view the sales snapshots chart')
              return
            }
            this.chartsPopupComponentId = uuidv4()
            const items = AmazonMonthlySalesDataSource.items()
            this.chartData = items
            // this.addPeriod()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.initialLoad()
          },
        },
        location: 'after',
      })
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        AmazonMonthlySalesDataSource.pageIndex(0)
        AmazonMonthlySalesDataSource.reload()
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    remove(row) {
      const confirmResult = confirm(
        "Are you sure you want to delete this Amazon monthly profit record? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await amazonReportService.deleteSalesSummary(row.data.id)
          if (result) {
            AmazonMonthlySalesDataSource.reload()
          }
        }
      })
    },
    onUpdaterClosed() {
      AmazonMonthlySalesDataSource.reload()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.getStores()
        this.searchByFilter()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
